import { useSelector } from 'react-redux';
import { getPaymentsMainTabs } from '../pages/Payments/PaymentController';

import { isToShowCustomerBillingTab, isUserOnboarded } from '../utils/access';
import { isScm, isCustomer, isCustomerAdmin, isCustomerUser, isCluster, isSuperVisor, isPsp, isSp, isIsp, isFe, SP, isSpIsp, isFM, isEndUser, isCustomerGroup, isBranchUser } from '../utils/role';
import { profileForm, businessForm, bankForm } from './constants';
import React, { useState, useEffect, lazy, Suspense } from 'react';

const Payment = lazy(() => import('../pages/Payments/Payments'));
const Projects = lazy(() => import('../pages/project/Projects'));
const Ticket = lazy(() => import('../pages/Ticket'));
const Inventory = lazy(() => import('../pages/Inventory/Inventory'));
const Chat = lazy(() => import('../pages/chat'));
const TeamWork = lazy(() => import('../pages/team/TeamWork'));
const CustomerBilling = lazy(() => import('../pages/CustomerBilling'));
const Profile = lazy(() => import('../pages/profile/Profile'));
const ResourceContainer = lazy(() => import('../pages/Resources/ResourceContainer'));
const Wallet = lazy(() => import('../pages/Wallet'));
const AddPOForm = lazy(() => import('../pages/Payments/components/AddPOForm'));
const TodayStatus = lazy(() => import('../pages/Ticket/TodayStatus'));
const CustomerOnboarding = lazy(() => import('../pages/Onboarding/CustomerOnboarding'));
const Onboarding = lazy(() => import('../pages/Onboarding/SP'));
const TicketExecution = lazy(() => import('../pages/ticket-execution'));
const EditTicket = lazy(() => import('../pages/edit-ticket'));
const ClientsTab = lazy(() => import('../pages/Clients/ClientsTab'));
const UserProfile = lazy(() => import('../pages/UserProfile'));
const RaisePo = lazy(() => import('../pages/raise-po'));
const EditPayoutpage = lazy(() => import('../pages/edit-payout'));
const FeOnboarding = lazy(() => import('../pages/Onboarding/Fe'));
const SupportTrack = lazy(() => import('../pages/SupportTrack'));
const Details = lazy(() => import('../pages/Details'));
const TrackPage = lazy(() => import('../pages/SupportTrack/Track'));
const SendInvitesContainer = lazy(() => import('../pages/team/SendInviteContainer'));
const GrafanaDashboard = lazy(() => import('../components/GrafanaDashboard'));

const withoutLayout = ({ children }) => {
    return children;
};

const loader = <div>Loading...</div>;
const home = {
    path: '/home',
    component: <div>Home</div>,
};
export const projects = {
    path: '/projects',
    component: (
        <Suspense fallback={loader}>
            <Projects />
        </Suspense>
    ),
};
const ticketsExecution = {
    path: '/tickets/:ticketId/execute/:step',
    component: (
        <Suspense fallback={loader}>
            <TicketExecution />
        </Suspense>
    ),
};
export const teamWork = {
    path: '/teams',
    component: (
        <Suspense fallback={loader}>
            <TeamWork />
        </Suspense>
    ),
};

const ticketEdit = {
    path: '/tickets/:ticketId/edit',
    component: (
        <Suspense fallback={loader}>
            <EditTicket />
        </Suspense>
    ),
};

export const inventory = {
    path: '/inventory',
    component: (
        <Suspense fallback={loader}>
            <Inventory />
        </Suspense>
    ),
};

const profile = {
    path: '/profile/:profileId',
    component: (
        <Suspense fallback={loader}>
            <Profile />
        </Suspense>
    ),
    layout: withoutLayout,
};

const resources = {
    path: '/documents',
    component: (
        <Suspense fallback={loader}>
            <ResourceContainer />
        </Suspense>
    ),
};

export const payments = {
    path: '/payments',
    component: (
        <Suspense fallback={loader}>
            <Payment />
        </Suspense>
    ),
};

export const wallet = {
    path: '/wallet',
    component: (
        <Suspense fallback={loader}>
            <Wallet />
        </Suspense>
    ),
};

const addNewPO = {
    path: '/addNewPO',
    component: (
        <Suspense fallback={loader}>
            <AddPOForm />
        </Suspense>
    ),
};

const userProfile = {
    path: '/user-profile',
    component: (
        <Suspense fallback={loader}>
            <UserProfile />
        </Suspense>
    ),
};

// const helpdesk = {
//     path: '/helpdesk',
//     component: <Suspense fallback={loader}>
//<HelpDesk />
//</Suspense>
//,
// }

export const ticket = {
    path: '/tickets',
    component: (
        <Suspense fallback={loader}>
            <Ticket />
        </Suspense>
    ),
};

export const todayStatus = {
    path: '/today-status',
    component: (
        <Suspense fallback={loader}>
            <TodayStatus />
        </Suspense>
    ),
};

export const chats = {
    path: '/chats',
    component: (
        <Suspense fallback={loader}>
            <Chat />
        </Suspense>
    ),
};

export const chatPageUsingLink = {
    path: '/chats/:chatId',
    component: (
        <Suspense fallback={loader}>
            <Chat />
        </Suspense>
    ),
};

export const customerBilling = {
    path: '/customer-billing',
    component: (
        <Suspense fallback={loader}>
            <CustomerBilling />
        </Suspense>
    ),
};

export const customerOnboard = {
    path: '/onboarding/customer',
    component: (
        <Suspense fallback={loader}>
            <CustomerOnboarding />
        </Suspense>
    ),
};

export const feOnboard = {
    path: '/onboarding/fe',
    component: (
        <Suspense fallback={loader}>
            <FeOnboarding />
        </Suspense>
    ),
};

export const spOnboard = {
    path: '/onboarding/sp',
    component: (
        <Suspense fallback={loader}>
            <Onboarding />
        </Suspense>
    ),
};

export const raisePo = {
    path: `${ticket.path}/raise-po`,
    component: (
        <Suspense fallback={loader}>
            <RaisePo />
        </Suspense>
    ),
};

const clientsTab = {
    path: '/clients',
    component: (
        <Suspense fallback={loader}>
            <ClientsTab />
        </Suspense>
    ),
};

const editPayout = {
    path: `${ticket.path}/:ticketId/edit-payout`,
    component: (
        <Suspense fallback={loader}>
            <EditPayoutpage />
        </Suspense>
    ),
};

export const trackSupportTicket = {
    path: `/support/track`,
    component: (
        <Suspense fallback={loader}>
            <SupportTrack />
        </Suspense>
    ),
};

export const detailsPath = {
    path: `/details`,
    component: (
        <Suspense fallback={loader}>
            <Details />
        </Suspense>
    ),
};

export const ticketTrackPath = {
    path: `/track`,
    component: (
        <Suspense fallback={loader}>
            <TrackPage />
        </Suspense>
    ),
};

export const sendInvitePath = {
    path: `/send-invite`,
    component: (
        <Suspense fallback={loader}>
            <SendInvitesContainer />
        </Suspense>
    ),
};

const Dashboard = () => {
    const { loggedUser } = useSelector((state) => state.auth);
    const [dashboardUrl, setDashboardUrl] = useState('');

    useEffect(() => {
        if (loggedUser.grafana_url) {
            setDashboardUrl(loggedUser.grafana_url);
        }
    }, [loggedUser.grafana_url]);

    return <GrafanaDashboard dashboardUrl={dashboardUrl} setDashboardUrl={setDashboardUrl} />
}

export const dashBoard = {
    path: `/dashboard`,
    component: (
        <Suspense fallback={loader}>
            <Dashboard />
        </Suspense>
    ),
};

export const getProtectedOnboardingRoutes = (role, type, { status }) => {
    if (isUserOnboarded(status)) return [];
    let routes = [];
    if (isCustomer(role, type)) routes = [customerOnboard];
    else if (isCustomerAdmin(role, type) || isCustomerUser(role, type)) routes = [customerOnboard];
    else if (isSp(role, type) || isIsp(role, type) || isSpIsp(role, type)) routes = [spOnboard];
    else if (role === SP.role) {
        routes = [spOnboard];
    } else if (isFe(role, type)) {
        routes = [feOnboard]
    }

    return routes;
};

export const getProtectedSidebarRoutes = (role, type, { frontend_controller, status, grafana_url, manage_partner_payments }) => {
    if (!isUserOnboarded(status)) return [];
    let routes = [home];
    if (isScm(role, type)) {
        routes = [
            projects,
            ticket,
            ticketsExecution,
            ticketEdit,
            teamWork,
            inventory,
            resources,
            payments,
            wallet,
            addNewPO,
            chats,
            chatPageUsingLink,
            todayStatus,
            clientsTab,
            userProfile,
            raisePo,
            customerBilling,
            editPayout,
            sendInvitePath
        ];
    }
    else if (isCustomer(role, type))
        routes = [projects, ticket, ticketsExecution, ticketEdit, teamWork, inventory, resources, payments, wallet, chats, chatPageUsingLink, todayStatus, userProfile, raisePo, editPayout, sendInvitePath];
    else if (isCustomerAdmin(role, type) || isCustomerUser(role, type))
        routes = [projects, ticket, ticketsExecution, ticketEdit, teamWork, inventory, resources, payments, wallet, chats, chatPageUsingLink, userProfile, raisePo, editPayout, sendInvitePath];
    else if (isCluster(role, type)) routes = [ticket, teamWork, ticketsExecution, ticketEdit, inventory, resources, payments, wallet, chats, chatPageUsingLink, userProfile, todayStatus, editPayout, sendInvitePath];
    else if (isSuperVisor(role, type)) routes = [ticket, teamWork, ticketsExecution, ticketEdit, inventory, resources, payments, chats, chatPageUsingLink, wallet, userProfile, editPayout, sendInvitePath];
    else if (isSp(role, type) || isIsp(role, type) || isSpIsp(role, type))
        routes = [ticket, teamWork, ticketsExecution, ticketEdit, inventory, resources, payments, wallet, chats, chatPageUsingLink, userProfile, editPayout, sendInvitePath];
    else if (isFe(role, type)) routes = [ticket, inventory, ticketsExecution, ticketEdit, payments, wallet, chats, chatPageUsingLink, userProfile, editPayout, sendInvitePath];
    else if (isPsp(role, type)) routes = [ticket, teamWork, ticketsExecution, ticketEdit, inventory, resources, payments, wallet, chats, chatPageUsingLink, userProfile, todayStatus, editPayout, sendInvitePath];
    else if (isIsp(role, type)) {
        routes = [home, ticket, ticketsExecution, ticketEdit, payments, wallet, chats, chatPageUsingLink, userProfile, editPayout, sendInvitePath];
    } else if (isFM(role)) {
        routes = [customerBilling];
    }
    routes = routes.filter((route) => {
        if (route.path === payments.path) {
            const paymentTabs = getPaymentsMainTabs(role, type, frontend_controller, manage_partner_payments);
            return paymentTabs.length > 0;
        } else if (route.path === customerBilling.path) {
            return isToShowCustomerBillingTab(frontend_controller)
        }
        return true;
    });

    if (isCustomerGroup(role, type) && grafana_url) {
        routes = [dashBoard, ...routes];
    }
    if (isBranchUser(role, type)) {
        routes = [dashBoard, chats];
    }
    return routes;
};

export const getDefaultRoute = (role, type, { frontend_controller, status, spOnboardingStatus, customerOnboardingStatus, feOnboardingStatus, email, pancard_number, address_proof_number }) => {
    let defaultPath = getProtectedSidebarRoutes(role, type, { frontend_controller, status })[0]?.path;

    if (isUserOnboarded(status)) return defaultPath;
    if (isCustomer(role, type)) {
        if (customerOnboardingStatus?.is_pending) {
            defaultPath = customerOnboard.path;
        }
    } else if (role === SP.role || isIsp(role, type) || isSp(role, type)) {
        if (!email || !type || (!pancard_number && !address_proof_number)) {
            defaultPath = `${spOnboard.path}?step=${profileForm.path}`;
        } else if (spOnboardingStatus?.company_detail) {
            defaultPath = `${spOnboard.path}?step=${businessForm.path}`;
        } else if (spOnboardingStatus?.bank_detail) {
            defaultPath = `${spOnboard.path}?step=${bankForm.path}`;
        }
    } else if (isFe(role, type)) {
        if (feOnboardingStatus?.field_engineer_detail) {
            defaultPath = feOnboard.path;
        }
    }
    return defaultPath;
};
